export enum ESocialMediaType {
  Facebook = 'facebookUrl',
  Twitter = 'twitterUrl',
  Linkedin = 'linkedinUrl',
  Telegram = 'telegramUrl',
  Instagram = 'instagramUrl',
  Weibo = 'weiboUrl',
  WeChat = 'weChatUrl',
  Youko = 'youkoUrl',
  Youtube = 'youtubeUrl',
}

export enum ESocialMediaIconStyle {
  Original = 'original',
  Black = 'black',
}
