import clsx from 'clsx';
import React from 'react';

import { ESocialMediaIconStyle, ESocialMediaType } from '../SocialMedia';
import SocialMedia, { TSocialMediaProps } from '../SocialMedia/SocialMedia';

export type TSocialMediaListProps = {
  companyLinks: {
    facebookUrl?: string;
    twitterUrl?: string;
    linkedinUrl?: string;
    telegramUrl?: string;
    instagramUrl?: string;
    youtubeUrl?: string;
    weiboUrl?: string;
    weChatUrl?: string;
    youkoUrl?: string;
  };
  iconStyle?: TSocialMediaProps['iconStyle'];
};

const SocialMediaList: React.FC<TSocialMediaListProps> = props => {
  const { companyLinks, iconStyle = ESocialMediaIconStyle.Original } = props;

  return (
    <div
      className={clsx('flex flex-row flex-wrap', {
        'gap-8 xl:py-[0.875rem]': iconStyle === ESocialMediaIconStyle.Original,
        'gap-7': iconStyle === ESocialMediaIconStyle.Black,
      })}
    >
      {companyLinks.twitterUrl && (
        <SocialMedia
          currentUrl={companyLinks.twitterUrl}
          socialPlatform={ESocialMediaType.Twitter}
          iconStyle={iconStyle}
        />
      )}
      {companyLinks.facebookUrl && (
        <SocialMedia
          currentUrl={companyLinks.facebookUrl}
          socialPlatform={ESocialMediaType.Facebook}
          iconStyle={iconStyle}
        />
      )}
      {companyLinks.linkedinUrl && (
        <SocialMedia
          currentUrl={companyLinks.linkedinUrl}
          socialPlatform={ESocialMediaType.Linkedin}
          iconStyle={iconStyle}
        />
      )}
      {companyLinks.telegramUrl && (
        <SocialMedia
          currentUrl={companyLinks.telegramUrl}
          socialPlatform={ESocialMediaType.Telegram}
          iconStyle={iconStyle}
        />
      )}
      {companyLinks.instagramUrl && (
        <SocialMedia
          currentUrl={companyLinks.instagramUrl}
          socialPlatform={ESocialMediaType.Instagram}
          iconStyle={iconStyle}
        />
      )}
      {companyLinks.youtubeUrl && (
        <SocialMedia
          currentUrl={companyLinks.youtubeUrl}
          socialPlatform={ESocialMediaType.Youtube}
          iconStyle={iconStyle}
        />
      )}
      {companyLinks.weiboUrl && (
        <SocialMedia
          currentUrl={companyLinks.weiboUrl}
          socialPlatform={ESocialMediaType.Weibo}
          iconStyle={iconStyle}
        />
      )}
      {companyLinks.weChatUrl && (
        <SocialMedia
          currentUrl={companyLinks.weChatUrl}
          socialPlatform={ESocialMediaType.WeChat}
          iconStyle={iconStyle}
        />
      )}
      {companyLinks.youkoUrl && (
        <SocialMedia
          currentUrl={companyLinks.youkoUrl}
          socialPlatform={ESocialMediaType.Youko}
          iconStyle={iconStyle}
        />
      )}
    </div>
  );
};

export default SocialMediaList;
